import React from "react"

const enBios = {
  "alli-tunell": (
    <p>
      Alli Tunell is a Content Marketing Leader with 10+ years of experience.
      She specializes in Saas Content Strategy, but also works within the
      eCommerce and DTC space. She currently manages a high-production team at
      Animalz. Prior to working at Animalz, she created the content department
      at BombBomb. Alli is also a mom to an energetic toddler and two fur
      babies. She lives with her family in Colorado Springs, CO.
    </p>
  ),
  "ashley-faus": (
    <p>
      Ashley Faus is a marketer, writer, and speaker by day, and a singer,
      actress, and fitness fiend by night. Her work has been featured in TIME,
      Forbes, and The Journal of Brand Strategy, and she's shared insights with
      audiences at Harvard Business Review, INBOUND, and MarketingProfs. She
      works for Atlassian, a collaboration software maker on a mission to
      unleash the power of every team. Follow her on LinkedIn and Twitter,
      @ashleyfaus.
    </p>
  ),
  "melissa-rosenthal": (
    <p>
      Melissa is an award winning marketing executive and the Chief Creative
      Officer at ClickUp, focused on making the world more productive through
      best in class SaaS marketing!
      <br /> Previously Melissa was the CRO/ Executive Vice President at
      Cheddar, and led BuzzFeed's Global Creative Team.
    </p>
  ),
  "alison-coleman": (
    <p>
      Alison Coleman is a Sr. Social Media Content Manager at Zoom. She works to
      create content that inspires, educates, and builds a deep connection with
      Zoom’s customers and fans. When she’s not on the corporate side of TikTok,
      you can find her on TravelTok, Celebrity TikTok, and Cleaning TikTok.
    </p>
  ),
  "anthony-kennada": (
    <p>
      Anthony Kennada is the Co-Founder & CEO of AudiencePlus. As the former
      Chief Marketing Officer of industry leading companies like Gainsight,
      Front, and Hopin, Anthony has built his career championing human-first
      brand building and community engagement. In 2019, he published his
      marketing playbook with Wiley in a book titled{" "}
      <span style={{ fontStyle: "italic" }}>
        Category Creation: How to Build a Brand that Customers, Employees, and
        Investors Will Love
      </span>
      . Anthony serves as an investor, advisor, and board member to SaaS
      companies across the globe.
    </p>
  ),
  "nate-martin": (
    <p>
      Nate leads content marketing at Notion, the all-in-one workspace that
      combines essential work tools — notes, dots, wikis, and project management
      — in one collaborative, customizable place. Previously, he's been a
      copywriter, growth marketer, and journalist. He also holds an MFA in
      creative writing.
    </p>
  ),
  "jimmy-daly": (
    <p>
      Jimmy is a longtime B2B content marketer who previously worked at Vero,
      QuickBooks and Animalz. Today, he runs Superpath, a content community with
      9k+ members, a job board and a freelance marketplace. He lives in Vail, CO
      with his wife, daughter and dog.
    </p>
  ),
  "thibaut-machet": (
    <p>
      Thibaut Machet is the CEO and co-founder of PlayPlay. Before starting his
      own company, Thibaut led Social Media at Eurosport, where he imagined the
      video creation tool that his teams so badly needed. In 2017, he launched
      PlayPlay with Clément and Aurélien, former colleagues at Eurosport. Today,
      PlayPlay has 280 employees and supports more than 1,500 Enterprise
      companies in France and internationally to reach their audience with
      video.
    </p>
  ),
}

export default enBios
