import PropTypes from "prop-types"
import React, { useRef } from "react"
import { useIntl } from "react-intl"

import MicrophoneSmall from "@/svgs/MicrophoneSmall/MicrophoneSmall"
import NavbarLogo from "@/svgs/NavbarLogo/NavbarLogo"
import NavbarLogo2023 from "@/svgs/NavbarLogo2023/NavbarLogo2023"
import RetroIcon from "@/svgs/RetroIcon/RetroIcon"

import * as styles from "./Navbar.module.scss"

const Navbar = ({ scrolltoConf, scrolltoForm, scrolltoRetro }) => {
  const intl = useIntl()
  const voirconfbutton = useRef(null)
  const registerButton = useRef(null)

  return (
    <div className={styles.NavbarComponent}>
      <div className={styles.wrapper}>
        {intl.locale !== "de" && (
          <div className={styles.logoContainer}>
            <NavbarLogo />
          </div>
        )}
        {intl.locale === "de" && (
          <div className={styles.logoContainer}>
            <NavbarLogo2023 />
          </div>
        )}
        <div className={styles.anchorRight}>
          <div>
            <button
              className={styles.link}
              onClick={() => scrolltoConf()}
              ref={voirconfbutton}
            >
              <MicrophoneSmall />
              <p>{intl.formatMessage({ id: "navbar-lineup" })}</p>
            </button>
          </div>
          {intl.locale === "fr" && (
            <div>
              <button
                className={styles.link}
                onClick={() => scrolltoRetro()}
                ref={voirconfbutton}
              >
                <RetroIcon />
                <p>{intl.formatMessage({ id: "replay2021" })}</p>
              </button>
            </div>
          )}
          {intl.locale === "fr" && (
            <div>
              <a className={styles.link} href="/fr/replay2022">
                <RetroIcon />
                <p className={styles.retro2022p}>
                  {intl.formatMessage({ id: "replay2022" })}
                </p>
              </a>
            </div>
          )}
          {intl.locale === "en" && (
            <div>
              <a className={styles.link} href="/replay2022">
                <RetroIcon />
                <p className={styles.retro2022p}>
                  {intl.formatMessage({ id: "replay2022" })}
                </p>
              </a>
            </div>
          )}
          {intl.locale === "de" && (
            <div>
              <button
                className={styles.cta}
                onClick={() => scrolltoForm()}
                ref={registerButton}
              >
                {intl.formatMessage({ id: "register" })}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar

Navbar.propTypes = {
  scrolltoConf: PropTypes.func,
  scrolltoForm: PropTypes.func,
  scrolltoRetro: PropTypes.func,
}
