import { graphql, useStaticQuery } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import microImg from "@/assets/images/micro@2x.png"
import BackgroundWall from "@/components/BackgroundWall/BackgroundWall"
import DeSpeakers from "@/components/DeSpeakers/DeSpeakers"
import EnSpeakers from "@/components/EnSpeakers/EnSpeakers"
import FrSpeakers from "@/components/FrSpeakers/FrSpeakers"
import SvgMask from "@/components/SvgMask/SvgMask"
import useWindowSize from "@/hooks/UseWindowSize"

import * as styles from "./SpeakerSection.module.scss"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const SpeakerSection = ({ speakerImages, spearkersSectionRef }) => {
  const microPng = useStaticQuery(graphql`
    query microphoneQuery {
      allFile(filter: { relativePath: { eq: "images/micro@2x.png" } }) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const sectionRef = useRef(null)
  const titleRef = useRef(null)
  const lightMaskRef = useRef(null)
  const [lightMaskSize, setLightMaskSize] = useState([0, 0])
  const intl = useIntl()

  const size = useWindowSize()

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
    const titlePosition = titleRef.current.getBoundingClientRect()
    gsap.to(lightMaskRef.current, {
      r: 100,
      attr: {
        cx: titlePosition.x + titleRef.current.clientWidth / 2,
        cy: 200,
      },
      opacity: 0,
      duration: 0,
    })

    ScrollTrigger.create({
      trigger: sectionRef.current,
      start: "top top",
      end: "top top",
      // markers: true,
      onEnter: () => {
        let tl = gsap.timeline()
        tl.to(lightMaskRef.current, {
          r: 400,
          opacity: 1,
          duration: 0.2,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 200,
          duration: 0.1,
        })
        tl.to(lightMaskRef.current, {
          r: 400,
          duration: 0.1,
        })
      },
      onLeaveBack: () => {
        gsap.to(lightMaskRef.current, {
          r: 100,
          opacity: 0,
          duration: 0.2,
        })
      },
    })
  }, [])

  useEffect(() => {
    setLightMaskSize([
      sectionRef.current.clientWidth,
      sectionRef.current.clientHeight,
    ])
  }, [size])

  return (
    <div className={styles.SpeakerSectionComponent} ref={sectionRef}>
      <div className={styles.wrapper} ref={spearkersSectionRef}>
        <div className={styles.title__wrapper}>
          <h2 className={styles.title} ref={titleRef}>
            <FormattedMessage id="speakers" />
          </h2>
          <div className={styles.micro_illu}>
            <img src={microImg} width="100px" alt="Micro Logo" />
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.speakerCardWrapper}>
          {intl.locale === "fr" && <FrSpeakers speakerImages={speakerImages} />}
          {intl.locale === "en" && <EnSpeakers speakerImages={speakerImages} />}
          {intl.locale === "de" && <DeSpeakers speakerImages={speakerImages} />}
        </div>
      </div>
      <SvgMask
        lightMaskRef={lightMaskRef}
        lightMaskSize={lightMaskSize}
        maskid={"speakersection"}
      />
      <BackgroundWall />
    </div>
  )
}

export default SpeakerSection

SpeakerSection.propTypes = {
  speakerImages: PropTypes.array,
  spearkersSectionRef: PropTypes.object,
}

// SpeakerSection.defaultProps = {}
