// extracted by mini-css-extract-plugin
export var title_description = "Form-module--title_description--6Lk0-";
export var FormComponent = "Form-module--FormComponent--LlGhg";
export var step = "Form-module--step--JOxAf";
export var step2 = "Form-module--step2--ByX31";
export var appear = "Form-module--appear--VDw4l";
export var step3 = "Form-module--step3--7eiiS";
export var formRow = "Form-module--formRow--AQLKj";
export var numberContainer = "Form-module--numberContainer--oyeDy";
export var closeBtn = "Form-module--closeBtn--Yxwhb";
export var cta = "Form-module--cta--KMB60";
export var companyContainer = "Form-module--companyContainer--yFtAt";