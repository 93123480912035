import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "react-intl"

import deBios from "@/components/DeSpeakers/deBios"
import SpeakerCard from "@/components/SpeakerCard/SpeakerCard"

const DeSpeakers = ({ speakerImages }) => {
  const intl = useIntl()
  const speakers = [
    "patrick-hollenbeck",
    "tiktok",
    "jason-modemann",
    "felix-pace",
    "anna-schwan",
    "jan-ahrens",
    "simone-bogner",
  ]

  return (
    <>
      {speakers.map(speakerId => {
        return (
          <SpeakerCard
            key={speakerId}
            name={intl.formatMessage({ id: `${speakerId}-name` })}
            job={intl.formatMessage({ id: `${speakerId}-job` })}
            title={intl.formatMessage({ id: `${speakerId}-title` })}
            startTime={intl.formatMessage({ id: `${speakerId}-start-time` })}
            bio={deBios[speakerId]}
            speakerImage={speakerImages.find(
              img => img.node.name === speakerId
            )}
          />
        )
      })}
    </>
  )
}

export default DeSpeakers

DeSpeakers.propTypes = {
  speakerImages: PropTypes.array,
}
