import PropTypes from "prop-types"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import * as styles from "./InviteForm.module.scss"

const InviteForm = ({ referrer, handleNextStep }) => {
  const [invites, setInvites] = useState([])
  const [number, setNumber] = useState(1)
  const intl = useIntl()

  const readCookie = name => {
    var nameEQ = name + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  const handleChangeInvite = (event, num) => {
    const value = event.target.value
    const currentInvites = invites
    const newInvites =
      num < currentInvites.length
        ? currentInvites.map((invite, index) =>
            index === num ? value : invite
          )
        : currentInvites.concat(value)
    setInvites(newInvites)
  }

  const handleAddNumber = () => {
    number < 5 && setNumber(number + 1)
  }

  const handleSubmitInvite = event => {
    event.preventDefault()

    // API call for referrer
    const frFormReferrerId = "79b5f449-cf34-40d0-ac00-e929a4259001"
    const usFormReferrerId = "01f1d160-dfc4-4a7f-aed3-3f825676cda4"
    const deFormReferrerId = "87ef213b-c618-4ce9-9ec7-f3847042d1d8"
    const formReferrerId = locale => {
      if (locale === "fr") return frFormReferrerId
      else if (locale === "de") return deFormReferrerId
      else return usFormReferrerId
    }
    fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/4746090/${formReferrerId(
        intl.locale
      )}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          context: {
            hutk: readCookie("hubspotutk"),
          },
          fields: [
            {
              name: "email",
              value: referrer.email,
            },
          ],
        }),
      }
    )

    // API call to invite collaborators
    const frFormId = "0fa84ff5-83b7-4473-a8af-23972a366346"
    const usFormId = "11099d01-7e30-4c70-a92a-d4457269423d"
    const deFormId = "c4a72602-e2e2-4f77-9a4f-e0a07bdee1fc"
    const formId = locale => {
      if (locale === "fr") return frFormId
      else if (locale === "de") return deFormId
      else return usFormId
    }
    const validInvites = invites.filter(invite => !!invite)
    validInvites.forEach((invite, index) => {
      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/4746090/${formId(
          intl.locale
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            context: {
              hutk: readCookie("hubspotutk"),
            },
            fields: [
              {
                name: "email",
                value: invite,
              },
              {
                name: "cs_2022___referrer_email",
                value: referrer.email,
              },
              {
                name: "cs_2022___referrer_first_name",
                value: referrer.firstName,
              },
              {
                name: "cs_2022___referrer_last_name",
                value: referrer.lastName,
              },
            ],
          }),
        }
      ).then(data => {
        data.status === 200 &&
          index === validInvites.length - 1 &&
          handleNextStep()
      })
    })
  }

  const numberArray = [...Array(number).keys()]

  return (
    <div className={styles.InviteFormComponent}>
      <div className={styles.step}>
        <p>{intl.formatMessage({ id: "subscription-message" })}</p>
        <div className={styles.inviteBlock}>
          <p>
            <strong>{intl.formatMessage({ id: "form-bonus" })}</strong>
            {intl.formatMessage({ id: "form-invite-message" })}
          </p>
          <form
            autoComplete="off"
            className={styles.form}
            onSubmit={handleSubmitInvite}
          >
            {numberArray.map(num => (
              <input
                key={num}
                type="email"
                name="inviteEmail"
                placeholder={intl.formatMessage({ id: "form-friends-email" })}
                onChange={event => handleChangeInvite(event, num)}
                value={invites[num] || ""}
                required={num === 0}
              />
            ))}
            {number < 5 && (
              <p
                role="presentation"
                className={styles.addButton}
                onClick={handleAddNumber}
              >
                + {intl.formatMessage({ id: "form-add-colleague" })}
              </p>
            )}
            <input
              type="submit"
              value={intl.formatMessage({ id: "form-send-invite" })}
              className={styles.cta}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default InviteForm

InviteForm.propTypes = {
  referrer: PropTypes.object,
  handleNextStep: PropTypes.func,
}
