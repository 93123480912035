import React from "react"

const QuitIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5625 12L17.6875 8.875C18.0938 8.5 18.0938 7.875 17.6875 7.5L17 6.8125C16.625 6.40625 16 6.40625 15.625 6.8125L12.5 9.9375L9.34375 6.8125C8.96875 6.40625 8.34375 6.40625 7.96875 6.8125L7.28125 7.5C6.875 7.875 6.875 8.5 7.28125 8.875L10.4062 12L7.28125 15.1562C6.875 15.5312 6.875 16.1562 7.28125 16.5312L7.96875 17.2188C8.34375 17.625 8.96875 17.625 9.34375 17.2188L12.5 14.0938L15.625 17.2188C16 17.625 16.625 17.625 17 17.2188L17.6875 16.5312C18.0938 16.1562 18.0938 15.5312 17.6875 15.1562L14.5625 12Z"
        fill="#0C1836"
      />
    </svg>
  )
}

export default QuitIcon
