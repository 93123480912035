import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "react-intl"

import * as styles from "./SelectInput.module.scss"

const SelectInput = ({ items, onChange, onClose }) => {
  const intl = useIntl()
  const sizes = [
    {
      value: "1-100",
      wording: "1 - 100",
    },
    {
      value: "100 - 250",
      wording: "100 - 250",
    },
    {
      value: "250 - 500",
      wording: "250 - 500",
    },
    {
      value: "500-1000",
      wording: "500 - 1000",
    },
    {
      value: "1000-5000",
      wording: "1000 - 5000",
    },
    {
      value: "5000 - 10000",
      wording: "5000 - 10000",
    },
    {
      value: "10000 +",
      wording: "10000 +",
    },
    {
      value: "Autre (freelance, étudiant...)",
      wording: intl.formatMessage({ id: "form-numemployees-other" }),
    },
  ]

  const jobs = [
    {
      value: "Achats",
      wording: intl.formatMessage({ id: "form-purchasing" }),
    },
    {
      value: "Audiovisuel",
      wording: intl.formatMessage({ id: "form-audiovisual" }),
    },
    {
      value: "Communication externe",
      wording: intl.formatMessage({ id: "form-external-com" }),
    },
    {
      value: "Communication Interne",
      wording: intl.formatMessage({ id: "form-internal-com" }),
    },
    {
      value: "Éditorial",
      wording: intl.formatMessage({ id: "form-editorial" }),
    },
    {
      value: "Marketing",
      wording: intl.formatMessage({ id: "form-marketing" }),
    },
    {
      value: "RH",
      wording: intl.formatMessage({ id: "form-hr" }),
    },
    {
      value: "Autre",
      wording: intl.formatMessage({ id: "form-other" }),
    },
  ]

  const industries = [
    {
      value: "Retail & E-Commerce",
      wording: "Retail & E-Commerce",
    },
    {
      value: "Media & Content",
      wording: "Media & Content",
    },
    {
      value: "Culture, Sports & Entertainment",
      wording: "Culture, Sports & Entertainment",
    },
    {
      value: "Public Administration",
      wording: "Public Administration",
    },
    {
      value: "FMCG",
      wording: "FMCG",
    },
    {
      value: "Games",
      wording: "Games",
    },
    {
      value: "Software",
      wording: "Software",
    },
    {
      value: "Education",
      wording: "Education",
    },
    {
      value: "Other Professional Services",
      wording: "Other Professional Services",
    },
  ]

  const elements =
    items === "numemployees"
      ? sizes
      : items === "industries"
      ? industries
      : jobs

  return (
    <ul className={styles.AutocompleteComponent} onMouseLeave={onClose}>
      {elements.map(element => {
        return (
          <li
            className={styles.element}
            key={element.value}
            onClick={() => onChange(element.value)}
            role="presentation"
          >
            <p>{element.wording}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default SelectInput

SelectInput.propTypes = {
  items: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
}
