import React from "react"

const MicrophoneSmall = () => {
  return (
    <svg
      width="62"
      height="41"
      viewBox="0 0 62 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5591 7.13385C23.3628 7.48327 21.9651 8.33186 21.9651 6.78443C21.9651 5.23701 23.6623 0.494917 32.5474 1.044C41.4326 1.59309 43.629 5.58643 43.7787 8.33186C44.1281 13.4234 41.0333 25.2537 41.0333 25.2537C40.3345 27.7994 40.1847 31.6929 30.4509 31.3435C21.8153 31.044 19.1198 27.0007 18.8702 25.2537C18.6207 24.3552 18.5707 22.9076 19.4193 21.8593C20.2679 20.7611 23.0633 20.6114 23.0633 20.6114"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M27.7554 6.98412C30.6007 6.68462 37.0399 7.08396 40.0349 10.1788"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M27.406 10.1788C29.3028 10.029 36.4909 10.5282 39.5358 13.623"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.9068 13.7728C28.8037 13.6231 35.9917 14.1222 39.0366 17.2171"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.0083 17.1172C28.8536 16.8177 35.1431 17.3169 38.1381 20.4117"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M25.4093 20.5115C28.2546 20.212 34.5441 20.7112 37.5391 23.806"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.1182 15.42C20.218 14.9209 21.4659 13.8726 25.0599 13.7229"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.2695 18.9142C19.3694 18.415 20.6173 17.3668 24.2113 17.217"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M24.2612 34.1389C24.2113 34.3385 23.1131 39.8294 26.807 40.1788C29.8519 40.4783 35.8918 39.7295 33.2463 34.9874"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M60.2013 40.2287C60.6007 38.3319 61 36.0856 61 34.039C61 24.0557 56.0582 15.2204 48.421 9.77945M1.599 40.1788C1.24958 38.2819 1 36.0357 1 34.0889C1 24.1056 5.94176 15.2703 13.579 9.82937"
        stroke="#FAB6E3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M52.9634 40.1788C53.2629 38.7312 53.6123 37.4334 53.6622 35.5365C53.9118 29.0973 51.3161 24.1555 47.5225 20.1622M8.83693 40.1788C8.53743 38.7811 8.28784 37.084 8.28784 35.5865C8.28784 29.6464 10.6339 24.2054 14.4276 20.2121"
        stroke="#79B2F3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.5175 12.0257C20.6173 11.5266 21.8652 10.4783 25.4592 10.3286"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MicrophoneSmall
