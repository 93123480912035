import PropTypes from "prop-types"
import React from "react"
import { useIntl } from "react-intl"

import enBios from "@/components/EnSpeakers/enBios"
import SpeakerCard from "@/components/SpeakerCard/SpeakerCard"

const EnSpeakers = ({ speakerImages }) => {
  const intl = useIntl()
  const speakers = [
    "alison-coleman",
    "melissa-rosenthal",
    "anthony-kennada",
    "jimmy-daly",
    "thibaut-machet",
    "nate-martin",
    "ashley-faus",
    "alli-tunell",
  ]

  return (
    <>
      {speakers.map(speakerId => {
        return (
          <SpeakerCard
            key={speakerId}
            name={intl.formatMessage({ id: `${speakerId}-name` })}
            job={intl.formatMessage({ id: `${speakerId}-job` })}
            title={intl.formatMessage({ id: `${speakerId}-title` })}
            startTime={intl.formatMessage({ id: `${speakerId}-start-time` })}
            startTime2={intl.formatMessage({ id: `${speakerId}-start-time-2` })}
            bio={enBios[speakerId]}
            speakerImage={speakerImages.find(
              img => img.node.name === speakerId
            )}
          />
        )
      })}
    </>
  )
}

export default EnSpeakers

EnSpeakers.propTypes = {
  speakerImages: PropTypes.array,
}
