import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { useIntl } from "react-intl"

import InfoIcon from "@/svgs/InfoIcon/InfoIcon"
import QuitIcon from "@/svgs/QuitIcon/QuitIcon"

import * as styles from "./SpeakerCard.module.scss"

const SpeakerCard = ({
  name,
  job,
  bio,
  title,
  startTime,
  startTime2,
  speakerImage,
}) => {
  const intl = useIntl()
  const [cardFlipped, setCardFlipped] = useState(false)
  const faceaRef = useRef(null)
  const facebRef = useRef(null)

  let speakerPicture = false
  speakerPicture =
    speakerImage && getImage(speakerImage.node.childImageSharp.gatsbyImageData)

  const flipCard = () => {
    let tl = gsap.timeline()
    if (cardFlipped) {
      tl.to(faceaRef.current, { rotationY: 0, duration: 0.5 })
      tl.to(facebRef.current, { rotationY: 180, duration: 0.5 }, "<")
      tl.add(function () {
        setCardFlipped(false)
      })
    } else {
      tl.to(faceaRef.current, { rotationY: 180, duration: 0.5 })
      tl.to(facebRef.current, { rotationY: "+=" + 180, duration: 0.5 }, "<")
      tl.add(function () {
        setCardFlipped(true)
      })
    }
  }

  return (
    <div className={styles.SpeakerCardComponent}>
      <div className={styles.facea} ref={faceaRef}>
        <div className={styles.wrapper}>
          <div className={styles.inner__wrapper}>
            <div className={styles.content}>
              <div className={styles.time}>
                <p>
                  {startTime}
                  {intl.locale === "en" && <span>PDT</span>}
                </p>
                {intl.locale === "en" && (
                  <p>
                    {startTime2}
                    <span>EST</span>
                  </p>
                )}
              </div>
              <div className={styles.name}>
                <p>{name}</p>
              </div>
              <div className={styles.job}>
                <p>{job}</p>
              </div>
              <div className={styles.desc}>
                <p>{title}</p>
              </div>
            </div>

            <button
              className={styles.moreInfoButton}
              onClick={() => flipCard()}
            >
              <InfoIcon />
            </button>

            <button className={styles.picture} onClick={() => flipCard()}>
              {speakerPicture && (
                <GatsbyImage
                  image={speakerPicture}
                  alt={name}
                  objectFit={"cover"}
                />
              )}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.faceb} ref={facebRef}>
        <div className={styles.wrapper}>
          <div className={styles.inner__wrapper}>
            <div className={styles.content}>
              <div className={styles.name}>
                <p>{name}</p>
              </div>
              <div className={styles.job}>
                <p>{job}</p>
              </div>
              <div className={styles.desc}>{bio}</div>

              <button
                className={styles.moreInfoButton}
                onClick={() => flipCard()}
              >
                <QuitIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpeakerCard

SpeakerCard.propTypes = {
  speakerImage: PropTypes.object,
  name: PropTypes.string,
  job: PropTypes.string,
  bio: PropTypes.object,
  title: PropTypes.string,
  startTime: PropTypes.string,
}
