import PropTypes from "prop-types"
import React from "react"

import * as styles from "./Autocomplete.module.scss"

const Autocomplete = ({ companies, onChooseCompanyName, onClose }) => {
  return (
    <ul className={styles.AutocompleteComponent} onMouseLeave={onClose}>
      {companies.map(company => {
        return (
          <li
            className={styles.element}
            key={company.name}
            onClick={() => onChooseCompanyName(company.name)}
            role="presentation"
          >
            <img src={company.logo} alt={company.name} />
            <p>{company.name}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default Autocomplete

Autocomplete.propTypes = {
  companies: PropTypes.array,
  onChooseCompanyName: PropTypes.func,
  onClose: PropTypes.func,
}
