import React from "react"

const InfoIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 17.2812C9.25 17.2812 9 17.5625 9 17.9062V19.375C9 19.75 9.25 20 9.625 20H14.375C14.7188 20 15 19.75 15 19.375V17.9062C15 17.5625 14.7188 17.2812 14.375 17.2812H13.75V10.625C13.75 10.2812 13.4688 10 13.125 10H9.625C9.25 10 9 10.2812 9 10.625V12.125C9 12.4688 9.25 12.75 9.625 12.75H10.25V17.2812H9.625ZM12 4C10.75 4 9.75 5.03125 9.75 6.25C9.75 7.5 10.75 8.5 12 8.5C13.2188 8.5 14.25 7.5 14.25 6.25C14.25 5.03125 13.2188 4 12 4Z"
        fill="#0C1836"
      />
    </svg>
  )
}

export default InfoIcon
