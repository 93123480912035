import PropTypes from "prop-types"
import React from "react"

import * as styles from "./SvgMask.module.scss"

const SvgMask = ({ lightMaskRef, lightMaskSize, maskid }) => {
  return (
    <div className={styles.SvgMaskComponent}>
      <svg
        width={lightMaskSize[0]}
        height={lightMaskSize[1]}
        viewBox={`0 0 ${lightMaskSize[0]} ${lightMaskSize[1]}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <radialGradient id="rg">
          <stop offset="0" stopColor="black" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <mask id={maskid}>
          <rect
            x="0"
            y="0"
            width={lightMaskSize[0]}
            height={lightMaskSize[1]}
            fill="white"
          />
          <circle cx="0" cy="0" r="100" fill="url(#rg)" ref={lightMaskRef} />
        </mask>
        <rect
          x="0"
          y="0"
          width={lightMaskSize[0]}
          height={lightMaskSize[1]}
          fill="#0c1836"
          mask={`url(#${maskid})`}
        />
      </svg>
    </div>
  )
}

export default SvgMask

SvgMask.propTypes = {
  lightMaskRef: PropTypes.object,
  lightMaskSize: PropTypes.array,
  maskid: PropTypes.string,
}

SvgMask.defaultProps = {}
