import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import languageLogo from "@/assets/images/world-icon.png"
import Form from "@/components/Form"
import Loader from "@/components/Loader/Loader"
import ChevronDown from "@/svgs/ChevronDown/ChevronDown"
import HeroLogoDe from "@/svgs/HeroLogoDe/HeroLogoDe"
import HeroLogoEn from "@/svgs/HeroLogoEn/HeroLogoEn"
import HeroLogoFr from "@/svgs/HeroLogoFr/HeroLogoFr"
import MicrophoneSmall from "@/svgs/MicrophoneSmall/MicrophoneSmall"
import RetroIcon from "@/svgs/RetroIcon/RetroIcon"

import * as styles from "./IntroSection.module.scss"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const IntroSection = ({ formRef, scrolltoConf, scrolltoRetro, withForm }) => {
  const loader = useRef(null)
  const sectionRef = useRef(null)
  const voirconfbutton = useRef(null)
  const intl = useIntl()

  useEffect(() => {
    //calculate radius intro
    let radiusmax = sectionRef.current.clientHeight / 2 - 40
    let radiusGradient = 600
    if (radiusGradient >= radiusmax) {
      radiusGradient = radiusmax
    }

    //timeline Intro Animation
    let tl = gsap.timeline()
    // tl.to(bottomNeons.current, { width: bottomNeonWidth, duration: 0 })
    tl.to(loader.current, {
      opacity: 0,
      duration: 0.4,
    })
    tl.to(loader.current, {
      display: "none",
      duration: 0,
    })

    //opacity Voir Conferenciers
    const op_tl = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "center top",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
    })
    op_tl.to(voirconfbutton.current, { opacity: 0 })
  }, [])

  return (
    <>
      <Loader loader={loader} />
      <div className={styles.IntroSectionComponent} ref={sectionRef}>
        <div className={styles.otherLanguage}>
          <a href={intl.formatMessage({ id: "other-language-url" })}>
            <img src={languageLogo} alt="Language Logo" />
            {intl.formatMessage({ id: "other-language-text" })}
          </a>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.left__wrapper}>
            {intl.locale === "fr" && <HeroLogoFr />}
            {intl.locale === "de" && <HeroLogoDe />}
            {intl.locale === "en" && <HeroLogoEn />}
            <div className={styles.navButtons}>
              <div
                className={styles.speakers_button}
                onClick={scrolltoConf}
                role="presentation"
              >
                <MicrophoneSmall />
                <p style={{ marginTop: "10px" }}>
                  <FormattedMessage id="hero-discover-speakers" />
                </p>
                <ChevronDown />
              </div>
              {intl.locale === "fr" && (
                <div
                  className={styles.speakers_button}
                  onClick={scrolltoRetro}
                  role="presentation"
                >
                  <RetroIcon />
                  <p>
                    <FormattedMessage id="discover-retro" />
                  </p>
                  <ChevronDown />
                </div>
              )}
            </div>
          </div>
          <div className={styles.right__wrapper}>
            <div className={styles.tags}>
              <span className={styles.virtual_event}>
                <FormattedMessage id="hero-virtual-event" />
              </span>
              <span className={styles.free_event}>
                <FormattedMessage id="hero-free-event" />
              </span>
            </div>
            <div className={styles.title_precisedate}>
              <p>
                <FormattedMessage id="date" />
              </p>
            </div>
            {withForm && <Form formRef={formRef} />}
            {intl.locale === "fr" && (
              <>
                <div className={styles.comments}>
                  <div className={styles.commentsCardLeft}>
                    <div className={styles.initials}>L.C</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Lionel CHENET <span>3:23 PM</span>
                      </p>
                      <p>
                        Merci à toute léquipe PlayPlay pour cette journée. C'est
                        top, inspirant... BRAVO ! et bravo pour l'aventure
                        PlayPlay !
                      </p>
                    </div>
                  </div>
                  <div className={styles.commentsCardRight}>
                    <div className={styles.initials}>A.L</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Anthony Lefrançois <span>5:25 PM</span>
                      </p>
                      <p>
                        Merci à vous pour l'organisation du Content Summit 2022,
                        c'est super avec des intervenants au top ! 😀
                      </p>
                    </div>
                  </div>
                  <div className={styles.commentsCardLeft}>
                    <div className={styles.initials}>S.M</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Sandra Mistral <span>5:02 PM</span>
                      </p>
                      <p>
                        Encore merci pour cette journée passionnante ! tous les
                        intervenants au top...Vraiment
                      </p>
                    </div>
                  </div>
                </div>
                <p className={styles.editionFinished}>
                  L'édition 2022 est maintenant terminée. Rendez-vous en 2023 !
                </p>
                <p className={styles.editionFinished}>
                  En attendant, découvrez les replays des 9 conférences 2022.
                </p>
                <div className={styles.ctaContainer}>
                  <a href="/fr/replay2022" className={styles.cta}>
                    Accédez aux replays
                  </a>
                </div>
              </>
            )}
            {intl.locale === "en" && (
              <>
                <div className={styles.comments}>
                  <div className={styles.commentsCardLeft}>
                    <div className={styles.initials}>N.V</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Nicole Naviglia <span>4:48 PM</span>
                      </p>
                      <p>
                        This is a fantastic virtual conference thank you to the
                        organizers!!!
                      </p>
                    </div>
                  </div>
                  <div className={styles.commentsCardRight}>
                    <div className={styles.initials}>E.L</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Emma Lough <span>5:03 PM</span>
                      </p>
                      <p>
                        I learned more from this summit than in the two years of
                        my career! Thank you for putting this together. It was
                        very informative!
                      </p>
                    </div>
                  </div>
                  <div className={styles.commentsCardLeft}>
                    <div className={styles.initials}>V.R</div>
                    <div className={styles.cardContent}>
                      <p className={styles.cardTitle}>
                        Victoria Rubio <span>5:07 PM</span>
                      </p>
                      <p>
                        It was really insightful and great speakers. Kudos and
                        thanks to the PlayPlay team!
                      </p>
                    </div>
                  </div>
                </div>
                <p className={styles.editionFinished}>
                  This edition is now finished. See you in 2023!
                </p>
                <p className={styles.editionFinished}>
                  In the meantime, discover the 8 replays of the 2022 edition.
                </p>
                <div className={styles.ctaContainer}>
                  <a href="/replay2022" className={styles.cta}>
                    Watch replays
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default IntroSection

IntroSection.propTypes = {
  formRef: PropTypes.object,
  scrolltoConf: PropTypes.func,
}
