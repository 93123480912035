// extracted by mini-css-extract-plugin
export var IntroSectionComponent = "IntroSection-module--IntroSectionComponent--6jpFk";
export var otherLanguage = "IntroSection-module--otherLanguage--M2BlK";
export var wrapper = "IntroSection-module--wrapper--q49xw";
export var left__wrapper = "IntroSection-module--left__wrapper--tRCuP";
export var navButtons = "IntroSection-module--navButtons---07fT";
export var speakers_button = "IntroSection-module--speakers_button--pr0lS";
export var right__wrapper = "IntroSection-module--right__wrapper--+f2QX";
export var virtual_event = "IntroSection-module--virtual_event--XQF25";
export var free_event = "IntroSection-module--free_event--yom6H";
export var title_precisedate = "IntroSection-module--title_precisedate--QYGZn";
export var title_description = "IntroSection-module--title_description--DvXPU";
export var tags = "IntroSection-module--tags--xl8zZ";
export var editionFinished = "IntroSection-module--editionFinished--5-Bc3";
export var ctaContainer = "IntroSection-module--ctaContainer--HZjT3";
export var cta = "IntroSection-module--cta--V3pKs";
export var comments = "IntroSection-module--comments--s67LO";
export var commentsCardRight = "IntroSection-module--commentsCardRight--6E4Ql";
export var commentsCardLeft = "IntroSection-module--commentsCardLeft--YZHhL";
export var initials = "IntroSection-module--initials--+rwY-";
export var cardTitle = "IntroSection-module--cardTitle--sRBUs";
export var cardContent = "IntroSection-module--cardContent--SmVCY";