// extracted by mini-css-extract-plugin
export var SpeakerCardComponent = "SpeakerCard-module--SpeakerCardComponent--vL7LF";
export var facea = "SpeakerCard-module--facea--Qf5ti";
export var faceb = "SpeakerCard-module--faceb---xROa";
export var wrapper = "SpeakerCard-module--wrapper--RwsKg";
export var inner__wrapper = "SpeakerCard-module--inner__wrapper--Bblql";
export var content = "SpeakerCard-module--content--aV0NR";
export var picture = "SpeakerCard-module--picture--E1pPs";
export var time = "SpeakerCard-module--time--WTcz4";
export var name = "SpeakerCard-module--name--kN6P9";
export var job = "SpeakerCard-module--job--NhhFW";
export var desc = "SpeakerCard-module--desc--OPelr";
export var cta__wrapper = "SpeakerCard-module--cta__wrapper--JwJdm";
export var moreInfoButton = "SpeakerCard-module--moreInfoButton--9IDfJ";