import React from "react"

const frBios = {
  "nina-ramen": (
    <p>
      Nina Ramen est créatrice de contenu spécialisée dans l’écriture de vente
      (copywriting). Aujourd’hui, c’est la référence dans son domaine. Avec :
      <br></br>- Une communauté de plus de 45 000 personnes sur LinkedIn,
      <br></br>- 2 formations en vente (+ 500 apprenants),<br></br>- 50
      interviews dans différents médias.
    </p>
  ),
  "antoine-filliaudeau": (
    <p>
      Antoine a commencé sa carrière au sein de l'industrie des médias NewGen.
      Il a travaillé chez Dojo, un pure player britannique créé en 2014, qui a
      évolué vers une agence de contenu et d'influence GenZ de premier ordre,
      appelée Kyra. Il a également travaillé pour les industries du cinéma et de
      la culture, avant de rejoindre JobTeaser en tant que Content Manager, il y
      a quatre ans. En février 2021, il a lancé la Content Factory de JobTeaser,
      qui est en charge du développement des stratégies de marque, de contenu et
      de création de l'entreprise.
    </p>
  ),
  "caroline-mignaux": (
    <p>
      Co-fondatrice de Reachmaker et auteure du podcast “Marketing Square”,
      Caroline est une spécialiste en acquisition social media et coach LinkedIn
      Booster : une méthode accélérée pour vous rendre autonome et efficace en
      un temps record.
    </p>
  ),
  "clara-landecy": (
    <p>
      Clara travaille au sein de l'équipe marketing française de HubSpot depuis
      4 ans. Son rôle de Senior Marketing Manager est principalement axé sur la
      génération de demande et le co-marketing. HubSpot est une plateforme
      intégrée de logiciels de marketing, de vente, de service client,
      d'exploitation et de CRM, conçue pour renforcer les équipes et aider les
      entreprises à se développer.
    </p>
  ),
  "alexis-chevalier": (
    <p>
      Actuellement CMO chez YouLoveWords, Alexis est rentré dans l'entreprise en
      tant qu'Inbound Marketer en octobre 2018. Depuis son arrivée, il a aidé
      YouLoveWords à atteindre un chiffre d'affaires à sept chiffres grâce au
      marketing et à devenir la première entreprise de marketing de contenu en
      France.
    </p>
  ),
  "guilhem-bertholet": (
    <p>
      Guilhem est un créateur de contenu passionné depuis 1999. Il a plongé dans
      le blogging et n'a jamais cessé depuis. Aujourd'hui, il développe Invox,
      une agence d'Inbound Marketing B2B, avec une équipe forte de 40 experts.
      Sa devise : construire des machines à générer des leads en combinant du
      contenu de qualité, de l'audience (SEO, médias sociaux, cold mailing) et
      des outils (principalement HubSpot-Eloqua-Pardot). Il adore aussi
      cuisiner.
    </p>
  ),
  "arnaud-maillard": (
    <p>
      Arnaud est un entrepreneur du monde digital avec plus de 20 ans
      d'expérience en tant que dirigeant de médias, gérant des opérations
      mondiales. Avant de lancer Loopsider, Arnaud était en charge des activités
      numériques de Discovery en dehors des Etats-Unis. Il a également travaillé
      chez Eurosport, Microsoft et AOL.
    </p>
  ),
  "thibaut-machet": (
    <p>
      Thibaut Machet est le CEO et co-fondateur de PlayPlay. Avant de se créer
      son entreprise, Thibaut a fait ses armes dans le milieu du Social Media
      chez Eurosport. Il imagine alors l'outil de création vidéo dont ses
      équipes auraient grand besoin. En 2017, il lance PlayPlay avec Clément et
      Aurélien, ses anciens collègues d'Eurosport. Aujourd'hui, PlayPlay compte
      280 employés et vient en aide à plus de 1 500 grandes entreprises en
      France et à l'international.
    </p>
  ),
  "jeremie-amram": (
    <p>
      Diplômé de Sciences Po, Jérémie Amram a fait ses armes parmi plusieurs
      agences créatives parisiennes tel que Fred&Farid Paris, BETC, ou encore We
      Are Social. Il pose ensuite ses valises chez TikTok, en devenant en 2020
      le premier employé du Creative Lab France en tant que tant que Creative +
      Strategist. Il participe à la conception et à la réalisation de campagnes
      intégrées à la plateforme pour de nombreuses marques (multi-secteurs),
      tout en accompagnant agences et partenaires dans la compréhension des
      usages et de la création.
    </p>
  ),
}

export default frBios
