import React from "react"
import { useIntl } from "react-intl"

import * as styles from "./StickyMobileCta.module.scss"

const StickyMobileCta = ({ scrolltoForm }) => {
  const intl = useIntl()

  return (
    <div className={styles.StickyMobileCtaComponent}>
      <button className={styles.cta} onClick={() => scrolltoForm()}>
        {intl.formatMessage({ id: "register" })}
      </button>
    </div>
  )
}

export default StickyMobileCta
