import React from "react"

const deBios = {
  "simone-bogner": (
    <p>
      Simone Bogner ist Content Managerin bei dem SaaS-Unternehmen Agorapulse.
      Sie ist ausgebildete Journalistin und hat langjährige Erfahrungen mit
      360°-Marketingkampagnen (u.a. für Softwareunternehmen, Verlag,
      Unternehmensberatung). Ihr Fokus liegt auf der crossmedialen
      Content-Produktion.
    </p>
  ),
  "jason-modemann": (
    <p>
      Der Traum eines jeden Marketers: Das gläserne Social Media Creative, bei
      dem du schon vor Go Live den Impact vorhersagen kannst. Aber wie erstellt
      man das perfekte Creative? Als Gründer & Geschäftsführer der in DACH
      führenden Social Performance Agentur Mawave Marketing GmbH hat Jason ein
      Blueprint genau für diese Herausforderung erstellt. Bring deine Creatives
      mit dieser Session aufs nächste Level!
    </p>
  ),
  "jan-ahrens": (
    <p>
      Im heutigen Informationsüberfluss ist es entscheidend, Themen so
      aufzubereiten, dass sie Aufmerksamkeit erzeugen. Auch als B2B-Unternehmen
      solltest du bei deiner Zielgruppe mit Content punkten, der aus der Masse
      heraussticht und echten Mehrwert stiftet. Wie das geht? Mit Creative Data
      Storytelling. In seinem Vortrag zeigt dir Jan, wie du in fünf Schritten
      wirklich relevanten Content erstellst.
    </p>
  ),
  "felix-pace": (
    <p>
      Felix ist ein erfolgreicher Kreativdirektor und Manager, der amerikanische
      Tech-Unternehmen wie Twitter, Audible und zuletzt Netflix in Deutschland
      etabliert hat. Mit seiner Agentur Subliminal Society betreute er Kunden
      wie Levi’s, Pfizer, Lindt, Warner und Universal Music. Seine Arbeit wurde
      mit mehreren Preisen ausgezeichnet.<br></br>
      <br></br>How to WOW ist ein einfaches und sehr mächtiges Marketing
      Framework. Es wurde 2013 beim DLD NYC entwickelt und wird seitdem von
      globalen Marketing und Kreativ-Teams erfolgreich umgesetzt. Das System
      nutzt u.a. Einblicke aus Soziologie, Psychologie und Trendforschung und
      wird ständig weiterentwickelt, es wurde zuletzt auf der Transformamus
      Konferenz der Universität St.Gallen vorgestellt.
    </p>
  ),
  "anna-schwan": (
    <p>
      Wie unterhaltsam kann Transport- und Logistik auf der Schiene überhaupt
      sein und wie hilft Social Media einer B2B-Brand dabei Berührungspunkte mit
      einem low-involvement Produkt zu schaffen. Und weshalb ist das so wichtig
      für uns alle? In ihrem Vortrag gibt Anna-Lena spannende Insights zu einer
      der erfolgreichsten B2B-Kampagnen der letzten Jahren.
    </p>
  ),
  "patrick-hollenbeck": (
    <p>
      Du möchtest wissen, wie du ganz ohne großen Zeit- und Kostenaufwand
      performanten Video Content für TikTok, Instagram und Co. erstellen kannst?
      Patrick zeigt dir, wie Brands und Unternehmen in nur fünf einfachen
      Schritten wirklich hochwertige Inhalte erstellen.
    </p>
  ),
  tiktok: (
    <p>
      TikTok ist die führende Plattform für mobile Kurzvideos – eine
      unterhaltsame und vielfältige Welt, in der es für jede Stimme eine
      Zielgruppe gibt. In ihrem Vortrag wird Anna nicht nur einen Überblick über
      den einzigartigen Charakter der Plattform und der Nutzer*innen von TikTok
      geben, sondern auch aufzeigen, wie Unternehmen jeder Grösse auf TikTok
      ihre Marke von der kreativsten Seite zeigen können, für Markenaufbau- oder
      Abverkaufszwecke.<br></br>
      <br></br>Anna ist Account Managerin für die TikTok SMB High Value
      KundInnen der DACH Region, deren Wirkungsbereich nicht nur allgemein im
      E-Commerce liegen, sondern u.a. auch in der Finanzwelt, dem Aus-und
      Weiterbildungsbereich und der Entertainmentbranche. Mit Marketinglösungen
      von TikTok hilft sie ihren Kund:innen, Marketingstrategien von
      Markenaufbau bis hin zum Abverkauf zu entwickeln und zielführend
      umzusetzen. Vor ihrer Zeit bei TikTok arbeitete Anna bei Google und war
      dort für Großkund:innen aus den Bereichen Finanzen, Media und Pharma
      verantwortlich.
    </p>
  ),
}

export default deBios
