import React, { useState } from "react"
import { useIntl } from "react-intl"

import * as styles from "./LegalText.module.scss"

const LegalText = ({ onChangeLegalCheckbox, value }) => {
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  const frMessageShort = (
    <p>
      Ces informations sont recueillies par PlayPlay pour vous donner accès au
      Content Summit et, sauf opposition de votre part, vous inclure dans nos
      communications. <span>Voir plus</span>
    </p>
  )
  const enMessageShort = (
    <p>
      PlayPlay is collecting this information to provide your access to the
      Content Summit and, unless you object, to add you in our communications.{" "}
      <span>See More</span>
    </p>
  )

  const frMessage = (
    <p>
      Ces informations sont recueillies par PlayPlay pour vous donner accès au
      Content Summit et, sauf opposition de votre part, vous inclure dans nos
      communications. Dans ce cadre, vos données seront transmises uniquement à
      nos sous-traitants. Nous les supprimerons au plus tard 3 ans après notre
      dernier contact. Vous pouvez notamment accéder aux données vous
      concernant, les rectifier, demander leur effacement ou limiter leur
      traitement. Pour exercer vos droits ou pour toute question à ce sujet,
      écrivez-nous à{" "}
      <a href="mailto:privacy@playplay.com">privacy@playplay.com</a>. Plus
      d’informations sur vos droits ici:{" "}
      <a href="https://cnil.fr" target="_blank" rel="noreferrer">
        cnil.fr
      </a>
      . En cas de difficulté, vous pouvez adresser une réclamation directement
      auprès de la CNIL. <span>Voir moins</span>
    </p>
  )
  const enMessage = (
    <p>
      PlayPlay is collecting this information to provide your access to the
      Content Summit and, unless you object, to add you in our communications.
      In this context, your data will be communicated to our subcontractors
      only. We will delete it no later than 3 years after our last contact. You
      can access the information about you, rectify it, request its deletion or
      limit its processing. To exercise your rights or for any related question,
      please send an email to{" "}
      <a href="mailto:privacy@playplay.com">privacy@playplay.com</a>. More
      information on your rights here:{" "}
      <a href="https://cnil.fr" target="_blank" rel="noreferrer">
        cnil.fr
      </a>
      . In case you have any difficulty, you can send your claim directly to the
      CNIL. <span>See less</span>
    </p>
  )

  const deMessage = (
    <p>
      Einverständnis-Erklärung: Die Informationen zum Datenschutz habe ich zur
      Kenntnis genommen und stimme diesen hiermit zu. Ich akzeptiere, dass meine
      personenbezogenen Daten für Werbezwecke durch die PlayPlay genutzt werden
      können. Ich akzeptiere auch, dass meine Daten an die in der Agenda
      aufgeführten Partner-Unternehmen zur Nutzung zu Werbezwecken weitergegeben
      werden, sofern ich an der Session des jeweiligen Unternehmens teilnehme.
      Die Ansprache erfolgt per E-Mail oder Telefon. Der Widerruf zur Zustimmung
      gegenüber PlayPlay und/oder diesen Partnern ist jederzeit möglich (per
      Mail an
      <a href="mailto:privacy@playplay.com"> privacy@playplay.com</a>).
    </p>
  )

  return (
    <>
      {intl.locale !== "de" && (
        <div className={styles.LegalTextComponent}>
          {isOpen ? (
            <div role="presentation" onClick={handleClose}>
              {intl.locale === "fr" && frMessage}
              {intl.locale === "en" && enMessage}
            </div>
          ) : (
            <div role="presentation" onClick={handleOpen}>
              {intl.locale === "fr" && frMessageShort}
              {intl.locale === "en" && enMessageShort}
            </div>
          )}
        </div>
      )}
      {intl.locale === "de" && (
        <div className={styles.LegalTextComponentDe}>
          <input
            type="checkbox"
            checked={value}
            onChange={() => onChangeLegalCheckbox(!value)}
            required
          />
          <div>{deMessage}</div>
        </div>
      )}
    </>
  )
}

export default LegalText
