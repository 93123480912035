import PropTypes from "prop-types"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import Autocomplete from "@/components/Autocomplete/Autocomplete"
import InviteForm from "@/components/InviteForm/InviteForm"
import LegalText from "@/components/LegalText/LegalText"
import SelectInput from "@/components/SelectInput/SelectInput"

import numberImg from "../../assets/images/number.png"
import * as styles from "./Form.module.scss"

const Form = ({ formRef }) => {
  const intl = useIntl()
  const [step, setStep] = useState(1)
  const [companies, setCompanies] = useState([])
  const [sizeIsOpen, setSizeIsOpen] = useState(false)
  const [jobIsOpen, setJobIsOpen] = useState(false)
  const [industryIsOpen, setIndustryIsOpen] = useState(false)
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    numemployees: "",
    industry: "",
    companyDepartment: "",
    phone: "",
    legalCheckbox: false,
  })

  const handleChangeEmail = event => {
    setValues({ ...values, email: event.target.value })
    setStep(2)
  }

  const handleChangeFirstName = event => {
    setValues({ ...values, firstName: event.target.value })
  }

  const handleChangeLastName = event => {
    setValues({ ...values, lastName: event.target.value })
  }

  const handleChangePhone = event => {
    setValues({ ...values, phone: event.target.value })
  }

  const handleChangeLegalCheckbox = value => {
    setValues({ ...values, legalCheckbox: value })
  }

  const handleChangeCompany = event => {
    setValues({ ...values, company: event.target.value })
    if (event.target.value === "") {
      setCompanies([])
    } else {
      fetch(
        `https://autocomplete.clearbit.com/v1/companies/suggest?query=:${values.company}`
      )
        .then(response => response.json())
        .then(data => {
          setCompanies(data)
        })
    }
  }

  const handleChooseNumemployees = value => {
    setValues({ ...values, numemployees: value })
    setSizeIsOpen(false)
  }

  const handleChooseCompanyDepartment = value => {
    setValues({ ...values, companyDepartment: value })
    setJobIsOpen(false)
  }

  const handleChooseIndustry = value => {
    setValues({ ...values, industry: value })
    setIndustryIsOpen(false)
  }

  const handleChooseCompanyName = name => {
    setValues({ ...values, company: name })
    setCompanies([])
  }

  const handleCloseCompany = () => {
    setCompanies([])
  }

  const readCookie = name => {
    var nameEQ = name + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  const portalId = "4746090"
  const formGuid = locale => {
    if (locale === "fr") return "9236fa32-07ca-4087-a242-ebf3fd8b8f86"
    else if (locale === "de") return "e643d0ee-d1f2-4bb9-916a-71ea81825725"
    else return "6a44a136-0fec-43d7-a266-e120ca63b3a3"
  }

  const handleSubmit = event => {
    event.preventDefault()
    fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid(
        intl.locale
      )}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          context: {
            hutk: readCookie("hubspotutk"),
          },
          fields: [
            {
              name: "email",
              value: values.email,
            },
            {
              name: "firstname",
              value: values.firstName,
            },
            {
              name: "lastname",
              value: values.lastName,
            },
            {
              name: "phone",
              value: values.phone,
            },
            {
              name: "company",
              value: values.company,
            },
            {
              name: "numemployees",
              value: values.numemployees,
            },
            {
              name: "company_department",
              value: values.companyDepartment,
            },
            {
              name: "cs2023___dach___industry",
              value: values.industry,
            },
            {
              name: "LEGAL_CONSENT.subscription_type_5181000",
              value: values.legalCheckbox,
            },
          ],
        }),
      }
    ).then(data => {
      if (data.status === 200) {
        setStep(3)
        const visitor = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          company: values.company,
          numemployees: values.numemployees,
          company_department: values.companyDepartment,
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          visitor: visitor,
          event: "new_lead",
        })
        if (
          [
            "250 - 500",
            "500-1000",
            "1000-5000",
            "5000 - 10000",
            "10000 +",
          ].includes(values.numemployees) &&
          [
            "Marketing",
            "Communication externe",
            "Communication interne",
            "RH",
          ].includes(values.companyDepartment)
        ) {
          window.dataLayer.push({
            visitor: visitor,
            event: "new_mql",
          })
        }
      }
    })
  }

  const sizesMapping = {
    "1-100": "1 - 100",
    "100 - 250": "100 - 250",
    "250 - 500": "250 - 500",
    "500-1000": "500 - 1000",
    "1000-5000": "1000 - 5000",
    "5000 - 10000": "5000 - 10000",
    "10000 +": "10000 +",
    "Autre (freelance, étudiant...)": intl.formatMessage({
      id: "form-numemployees-other",
    }),
  }

  const industriesMapping = {
    "Retail & E-Commerce": "Retail & E-Commerce",
    "Media & Content": "Media & Content",
    "Culture, Sports & Entertainment": "Culture, Sports & Entertainment",
    "Public Administration": "Public Administration",
    FMCG: "FMCG",
    Games: "Games",
    Software: "Software",
    Education: "Education",
    "Other Professional Services": "Other Professional Services",
  }

  const jobsMapping = {
    Marketing: intl.formatMessage({ id: "form-marketing" }),
    "Communication externe": intl.formatMessage({ id: "form-external-com" }),
    "Communication Interne": intl.formatMessage({ id: "form-internal-com" }),
    Achats: intl.formatMessage({ id: "form-purchasing" }),
    RH: intl.formatMessage({ id: "form-hr" }),
    Audiovisuel: intl.formatMessage({ id: "form-audiovisual" }),
    Éditorial: intl.formatMessage({ id: "form-editorial" }),
    Autre: intl.formatMessage({ id: "form-other" }),
  }

  return (
    <div ref={formRef}>
      {(step === 1 || step === 2) && (
        <>
          <div className={styles.title_description}>
            <p>{intl.formatMessage({ id: "hero-description-event1" })}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: "hero-description-event2" }),
              }}
            />
            {intl.locale === "en" && (
              <p>{intl.formatMessage({ id: "hero-description-event3" })}</p>
            )}
          </div>
          <form
            autoComplete="off"
            className={styles.FormComponent}
            onSubmit={handleSubmit}
          >
            <div className={styles.step}>
              <input
                type="email"
                name="email"
                placeholder={intl.formatMessage({ id: "form-work-email" })}
                onInput={handleChangeEmail}
                value={values.email}
                required
              />
            </div>
            {step === 2 && (
              <div className={styles.step2}>
                <div className={styles.formRow}>
                  <input
                    name="firstName"
                    placeholder={intl.formatMessage({ id: "form-first-name" })}
                    onChange={handleChangeFirstName}
                    value={values.firstName}
                    required
                  />
                  <input
                    name="lastName"
                    placeholder={intl.formatMessage({ id: "form-last-name" })}
                    onChange={handleChangeLastName}
                    value={values.lastName}
                    required
                  />
                </div>
                {intl.locale === "de" && (
                  <input
                    name="phone"
                    type="tel"
                    placeholder={intl.formatMessage({ id: "form-phone" })}
                    onChange={handleChangePhone}
                    value={values.phone}
                  />
                )}
                <div className={styles.companyContainer}>
                  <input
                    name="company"
                    placeholder={intl.formatMessage({ id: "form-company" })}
                    onChange={handleChangeCompany}
                    value={values.company}
                    required
                  />
                  {companies.length > 0 && (
                    <>
                      <span
                        className={styles.closeBtn}
                        onClick={handleCloseCompany}
                        role="presentation"
                      >
                        X {intl.formatMessage({ id: "form-close" })}
                      </span>
                      <Autocomplete
                        companies={companies}
                        onChooseCompanyName={handleChooseCompanyName}
                        onClose={handleCloseCompany}
                      />
                    </>
                  )}
                </div>
                <div className={styles.companyContainer}>
                  <input
                    name="numemployees"
                    placeholder={intl.formatMessage({
                      id: "form-numemployees",
                    })}
                    onClick={() => setSizeIsOpen(true)}
                    value={sizesMapping[values.numemployees] || ""}
                    onChange={() => handleChooseNumemployees("")}
                    required
                  />
                  {sizeIsOpen && (
                    <SelectInput
                      onChange={handleChooseNumemployees}
                      onClose={() => setSizeIsOpen(false)}
                      items="numemployees"
                    />
                  )}
                </div>
                {intl.locale === "de" && (
                  <div className={styles.companyContainer}>
                    <input
                      name="industry"
                      placeholder={intl.formatMessage({
                        id: "form-industry",
                      })}
                      onClick={() => setIndustryIsOpen(true)}
                      value={industriesMapping[values.industry] || ""}
                      onChange={() => handleChooseIndustry("")}
                      required
                    />
                    {industryIsOpen && (
                      <SelectInput
                        onChange={handleChooseIndustry}
                        onClose={() => setIndustryIsOpen(false)}
                        items="industries"
                      />
                    )}
                  </div>
                )}
                <div className={styles.companyContainer}>
                  <input
                    name="companyDepartment"
                    placeholder={intl.formatMessage({
                      id: "form-company-department",
                    })}
                    onClick={() => setJobIsOpen(true)}
                    value={jobsMapping[values.companyDepartment] || ""}
                    onChange={() => handleChooseCompanyDepartment("")}
                    required
                  />
                  {jobIsOpen && (
                    <SelectInput
                      onChange={handleChooseCompanyDepartment}
                      onClose={() => setJobIsOpen(false)}
                      items="companyDepartement"
                    />
                  )}
                </div>
              </div>
            )}
            <div className={styles.step}>
              <input
                type="submit"
                value={intl.formatMessage({ id: "register" })}
                className={styles.cta}
              />
            </div>
            {intl.locale === "fr" && step === 1 && (
              <div className={styles.numberContainer}>
                <img src={numberImg} alt="2021 Illustration" />
              </div>
            )}
            {step === 2 && (
              <LegalText
                onChangeLegalCheckbox={handleChangeLegalCheckbox}
                value={values.legalCheckbox}
              />
            )}
          </form>
        </>
      )}
      {step === 3 && (
        <InviteForm referrer={values} handleNextStep={() => setStep(4)} />
      )}
      {step === 4 && (
        <div className={styles.FormComponent}>
          <div className={styles.step3}>
            <p>{intl.formatMessage({ id: "form-referral-sent" })}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Form

Form.propTypes = {
  formRef: PropTypes.object,
}
