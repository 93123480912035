import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import * as styles from "./BackgroundWall.module.scss"

const BackgroundWall = () => {
  const backgroundImage = useStaticQuery(graphql`
    query MyQuery {
      file(name: { eq: "backgroundbrickslight" }) {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <div className={styles.BackgroundWallComponent}>
      <div className={styles.backgroundImage}>
        <GatsbyImage
          image={backgroundImage.file.childrenImageSharp[0].gatsbyImageData}
          objectFit={"cover"}
          alt="image"
        />
      </div>
    </div>
  )
}

export default BackgroundWall

BackgroundWall.propTypes = {
  lightMaskRef: PropTypes.object,
}
